import React, { useRef } from "react";
import '../../assets/css/input-field.css'
import ErrorMessage from "./ErrorMessage";

const FileUpload = ({ removeMargin = false, ...props }) => {
    return (
        <React.Fragment>
            <div className="form-field">
                <Input {...props} />
            </div>
        </React.Fragment>
    );
};

export default FileUpload;

const Input = ({
    label,
    children,
    field,
    form,
    className,
    disabled,
    onFileSelected,
    documentName,
    multiplefileUpload,
    ...props
}) => {
    let isDisabled = disabled ? "disabled" : "";
    let disabledClass = disabled ? "cursor-not-allowed" : "";
    let fileUploadRef = useRef();

    function handleChange(event, index) {
        if (!Array.from(fileUploadRef.files).every(file => file.size > 0)) {
            const dialog = document.createElement('dialog');
            dialog.innerHTML = `
            <div style="color: #18479a;">
                <h2>Warning!</h2>
                <p>Some files uploaded contain 0 bytes.</p>
                <p>Please download the file onto your server or computer, and reupload the file to AI Demand Pro</p>
                <button style="background-color: #18479a; color: white; padding: 8px 16px; border: none; border-radius: 4px; cursor: pointer;" onclick="this.closest('dialog').remove()">OK</button>
            </div>
        `;
            document.body.appendChild(dialog);
            dialog.showModal();
            fileUploadRef.value = '';
            return
        }
        console.log(event.target.value);
        onFileSelected({ path: fileUploadRef.value, file: multiplefileUpload ? fileUploadRef.files : fileUploadRef.files[0] });
    }

    return (
        <React.Fragment>
            {documentName === "medicalRecordsFile" ?
                <label htmlFor="filePicker" style={{ width: '100%' }}> {label}
                    <div>
                        <input
                            type="file"
                            ref={elementRef => fileUploadRef = elementRef}
                            multiple
                            // {...field}
                            // {...props}
                            accept="application/pdf"
                            className={documentName === "medicalProviderBills" ? `fileInputStyle` : `input-control file-upload-control ${className ?? ''} ${disabledClass}`}
                            disabled={isDisabled}
                            onChange={handleChange}
                            id="filePicker"
                            style={{ visibility: "hidden" }}

                        />
                        <ErrorMessage form={form} field={field} />
                    </div>
                </label>
                :
                <>
                    <label> {label} </label>
                    <div>
                        <input
                            type="file"
                            ref={elementRef => fileUploadRef = elementRef}
                            multiple
                            // {...field}
                            {...props}
                            className={documentName === "medicalProviderBills" ? `fileInputStyle` : `input-control file-upload-control ${className ?? ''} ${disabledClass}`}
                            disabled={isDisabled}
                            onChange={handleChange}
                            id={documentName === "medicalProviderBills" ? `fileInputStyle` : ''}
                        // style={{display: 'none'}}

                        />
                        <ErrorMessage form={form} field={field} />
                    </div>
                </>

            }

            {children}
        </React.Fragment>
    );
};
